<template>
  <v-container fluid class="pa-0" v-if="player">
    <v-row dense>
      <v-col cols="12" v-if="show.contacts">
        <v-card>
          <v-card-text class="subheading">
            <div>Show my phone number to:</div>
            <v-divider></v-divider>
            <v-switch :disabled="!editing" color="success" v-if="player.recruitFields" label="College Coaches" v-model="phone" value="coaches" hide-details></v-switch>
            <v-switch :disabled="!editing" color="success" label="Other Players" v-model="phone" value="players" hide-details class="mt-0"></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="show.contacts">
        <v-card>
          <v-card-text class="subheading">
            <div>Show my email address to:</div>
            <v-divider></v-divider>
            <v-switch :disabled="!editing" color="success" v-if="player.recruitFields" label="College Coaches" v-model="email" value="coaches" hide-details></v-switch>
            <v-switch :disabled="!editing" color="success" label="Other Players" v-model="email" value="players" hide-details class="mt-0"></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="show.upcoming">
        <v-card>
          <v-card-text class="subheading">
            <div>Show my upcoming tournaments to:</div>
            <v-divider></v-divider>
            <v-switch :disabled="!editing" color="success" v-if="player.recruitFields" label="College Coaches" v-model="up" value="coaches" hide-details></v-switch>
            <v-switch :disabled="!editing" color="success" label="Other Players" v-model="up" value="players" hide-details class="mt-0"></v-switch>
            <v-switch :disabled="!editing" color="success" label="Everyone" v-model="up" value="public" hide-details class="mt-0"></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="show.metrics">
        <v-card>
          <v-card-text class="subheading">
            <div>Show my volleyball metrics:</div>
            <v-divider></v-divider>
            <v-switch :disabled="!editing" color="success" v-if="player.recruitFields" label="College Coaches" v-model="metrics" value="coaches" hide-details></v-switch>
            <v-switch :disabled="!editing" color="success" label="Other Players" v-model="metrics" value="players" hide-details class="mt-0"></v-switch>
            <v-switch :disabled="!editing" color="success" label="Everyone" v-model="metrics" value="public" hide-details class="mt-0"></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text class="subheading">
            <div>User Access:</div>
            <v-divider></v-divider>
            <v-data-table
              :headers="userHeaders"
              :items="users"
              hide-default-footer
              item-key="id"
              :items-per-page="-1"
            >
              <template v-slot:[`item.lastConnected`]="{ item }">
                {{item.lastConnected | unixToLocalDt}}
              </template>
              <template v-slot:[`item.isMember`]="{ item }">
                <v-icon v-if="item.isMember" color="success" small>fas fa-check</v-icon>
              </template>
              <template v-slot:[`item.hasPassword`]="{ item }">
                <v-icon v-if="item.hasPassword" color="success" small>fas fa-check</v-icon>
              </template>
              <template v-slot:[`item.paidMember`]="{ item }">
                <v-icon v-if="item.paidMember" color="success" small>fas fa-check</v-icon>
              </template>
              <template v-slot:[`item.approved`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      :color="item.approved ? 'success' : 'warning'"
                    >
                      fas fa-user-{{ item.approved ? 'check' : 'clock' }}
                    </v-icon>
                  </template>
                  <span>{{ item.approved ? 'Approved' : 'Awaiting Approval' }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-fab-transition><!-- CANCEL -->
      <v-btn
        color="success white--text"
        fab
        :small="smallFab"
        fixed bottom right
        @click.stop="save"
        v-if="editing"
        :loading="loading"
        :disabled="!dirty"
        :style="smallFab ? 'margin-right: 45px;' : 'margin-right: 65px;'"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition><!-- EDIT/SAVE -->
      <v-btn
        v-if="fab"
        :color="fab.color"
        fab
        :small="smallFab"
        :key="fab.key"
        @click.stop="fab.click"
        :loading="fab.loading"
        :disabled="fab.disabled"
        fixed bottom right
      >
        <v-icon>{{fab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['player', 'active', 'only'],
  data () {
    return {
      loading: false,
      editing: false,
      up: [],
      phone: [],
      email: [],
      metrics: [],
      users: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    userHeaders () {
      return [
        { text: 'User Id', value: 'userId', hide: !(this.user && this.user.vbl) },
        { text: 'User', value: 'user' },
        { text: 'Relation', value: 'relation' },
        { text: 'Status', value: 'approved' },
        { text: 'Username', value: 'username' },
        { text: 'Last Connect', value: 'lastConnected' },
        { text: 'Member User', value: 'isMember', align: 'center' },
        { text: 'Has Password', value: 'hasPassword', align: 'center' },
        { text: 'Paid', value: 'paidMember', align: 'center', hide: !(this.user && this.user.vbl) }
      ].filter(f => !f.hide)
    },
    show () {
      return {
        contacts: !this.only || this.only.includes('contacts'),
        upcoming: !this.only || this.only.includes('upcoming'),
        metrics: !this.only || this.only.includes('metrics')
      }
    },
    jprops () {
      return this.player && this.player.jProps.settings
    },
    dirty () {
      if (!this.jprops) return true
      const j = this.jprops
      return (JSON.stringify(this.up) !== JSON.stringify(j.upcoming)) ||
      JSON.stringify(this.phone) !== JSON.stringify(j.phone) ||
      JSON.stringify(this.email) !== JSON.stringify(j.email) ||
      JSON.stringify(this.metrics) !== JSON.stringify(j.metrics)
    },
    dto () {
      if (!this.dirty) return null
      const j = Object.assign({}, this.player.jProps)
      j.settings.upcoming = this.up
      j.settings.phone = this.phone
      j.settings.email = this.email
      j.settings.metrics = this.metrics
      return {
        id: this.player.id,
        jsonProps: JSON.stringify(j)
      }
    },
    smallFab () {
      return this.$vuetify.breakpoint.smAndDown
    },
    fab () {
      return this.editing ? {
        key: 'cancel',
        color: 'error white--text',
        icon: 'fas fa-times',
        click: this.cancel,
        loading: this.loading
      } : {
        key: 'edit',
        color: 'color3 color3Text--text',
        icon: 'fas fa-pencil',
        click: this.edit,
        loading: false,
        disabled: this.loading
      }
    }
  },
  methods: {
    edit () {
      this.init()
      this.editing = true
    },
    cancel () {
      this.editing = false
      this.init()
    },
    save () {
      if (this.dto) {
        this.loading = true
        this.$VBL.player.patch(this.dto)
          .then(r => {
            this.player.update(this.dto)
            this.cancel()
          })
          .catch(e => { console.log(e.response) })
          .finally(() => {
            this.loading = false
            this.$emit('save-complete')
          })
      }
    },
    fetchUsers () {
      this.loading = true
      this.$VBL.player.users.get(this.player.id)
        .then(r => {
          this.users = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.loading = false
        })
    },
    init () {
      if (!this.player) return
      this.fetchUsers()
      const j = this.jprops
      this.up = j.upcoming || []
      this.phone = j.phone || []
      this.email = j.email || []
      this.metrics = j.metrics || []
      this.$emit('dirty-change', this.dirty)
    }
  },
  watch: {
    active: function (v) {
      if (v) {
        this.init()
      }
    },
    dirty: function (v) {
      this.$emit('dirty-change', v)
    },
    loading: function (v) {
      this.$emit('loading-change', v)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>

</style>
